
import Layout from "@/Layout/Layout.vue";
import GetHow from "@/sections/GetHow.vue";
import GetMeet from "@/sections/GetMeet.vue";
import GetRoadMap from "@/sections/GetRoadMap.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Layout, GetMeet, GetHow, GetRoadMap },
  name: "get-started",
  mounted() {
    window.scrollTo({ top: 0 });
  },
});
