
import MiniBox from "@/components/MiniBox.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { MiniBox },
  name: "get-road-map",
  data() {
    return {
      roads: [
        {
          id: "q1",
          auto: true,
          maps: [
            { title: "Product Launch", text: ["Savings & Ajo"], right: true },
            { title: "Fundraising", text: ["500k USD Pre-seed"], right: false },
            { title: "Onboard Users", text: ["First 10000"], right: true },
          ],
        },
        {
          id: "q2",
          auto: false,
          maps: [
            {
              title: "Feature Update",
              text: ["Bank Transfers", "Disruptive Payment", "E-stores"],
              right: true,
            },
            {
              title: "Loan Disbursement",
              text: ["Zero-Interest", "Business Loan"],
              right: false,
            },
            { title: "Users", text: ["2X Customer Growth"], right: true },
            { title: "Sales", text: ["10X Revenue Growth"], right: false },
          ],
        },
        {
          id: "q3",
          auto: false,
          maps: [
            {
              title: "Customer Acquisition",
              text: ["Implement growth strategy", "Improve product features"],
              right: true,
            },
            {
              title: "Non-interest",
              text: [
                "Increase Loan disbursement",
                "Implement debt recovery strategy",
              ],
              right: false,
            },
            {
              title: "User Growth",
              text: ["Onboard unbanked communities"],
              right: true,
            },
          ],
        },
        {
          id: "q4",
          auto: false,
          maps: [
            { title: "Feature Update", text: ["Pay withHalal"], right: true },
            {
              title: "Onboarding",
              text: ["Hire Growth Managers"],
              right: false,
            },
            { title: "Growth", text: ["Scale to other markets"], right: true },
          ],
        },
      ],
    };
  },
});
