
import { defineComponent } from "vue";

export default defineComponent({
  name: "get-how",
  data() {
    return {
      howBtmBox: [
        "d-flex",
        "flex-column",
        "flex-md-row",
        "align-items-center",
        "justify-content-center",
        "justify-content-md-between",
      ],
      plans: [
        {
          heading: "Zero Interest Loan",
          text: "In accordance with Sharia finance, Halal offers free loans to SMEs and individuals without the burden of interest.",
        },
        {
          heading: "Sukuk Investment",
          text: "We are truly halal by providing investment opportunities to our users, for them to have direct asset ownership in businesses with non speculative returns",
        },
        {
          heading: "Mudarabah",
          text: "We share profits with businesses through joint partnership by providing them capital, while they manage the operational aspects under our strategic guidance.",
        },
      ],
      hows: [
        {
          heading: "Sign Up",
          text: "If you run any business of any size, you need the Halal Ajo product to protect yourself during rainy days. What's more, you can get access to instant loans when you save consistently.",
        },
        {
          heading: "Join a Group",
          text: "Join Halal Ajo group or create a new one right on the app or through any of our daily collectors and start saving right away. ",
        },
        {
          heading: "Start Earning ",
          text: "Access numerous benefits of using Halal Ajo including premium financial literacy, cash back, and special rewards for discipline while growing your business. ",
        },
      ],
    };
  },
});
