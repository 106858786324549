
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "mini-box",
  props: {
    right: Boolean,
    heading: String,
    text: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
